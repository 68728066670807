import { Modal, Paper, Grid, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState, useContext } from "react";
import { UserContext } from "../../context/UserContext";
import ImageMarker from "react-image-marker";
import DotMarkerWithNumber from "../Common/DotMarkerWithNumber";
import { axiosInstance } from "../../utils/utils";

const BlueprintModal = props => {
    const [markers, setMarkers] = useState([]);
    const [image, setImage] = useState();
    const [userContext, setUserContext] = useContext(UserContext);

    const getImage = image => {
        return process.env.REACT_APP_API_URL + "/images/" + image + "?token=" + userContext.token;
    };

    useEffect(() => {
        const fetchBlueprintData = () => {
            let url = process.env.REACT_APP_API_URL + "/projects/blueprintdata?id=" + props.project._id;
            const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

            axiosInstance.get(url, config)
                .then(res => {
                    setMarkers(res.data.markers);
                    setImage(res.data.blueprint);
                })
                .catch(err => {
                    // Do nothing
                });
        };

        fetchBlueprintData();
    }, [userContext, setUserContext, props.project._id]);

    return (
        <Modal open={props.openModal} onClose={() => props.setOpenModal(false)}>
            <Paper
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    maxWidth: 800,
                    maxHeight: 1000,
                    width: "90%",
                    height: "90%",
                    bgcolor: "#d9d9d9",
                    overflow: "auto"
                }}
            >
                <Grid container item justifyContent="flex-end" sx={{ padding: "2.5% 2.5% 2.5% 0" }}>
                    <IconButton onClick={() => props.setOpenModal(false)}><CloseIcon /></IconButton>
                </Grid>
                <Grid container item direction="column" sx={{ padding: "0% 6% 6% 6%", minHeight: "90%" }}>
                    <Typography variant="h5" align="center" sx={{ mb: 4 }}>"{props.project.name}" Workscope Overview</Typography>
                    <Grid container item alignItems="center" sx={{ minHeight: "100%" }}>
                        {image ?
                            <ImageMarker
                                src={getImage(image)}
                                markers={markers}
                                markerComponent={DotMarkerWithNumber}
                            />
                            :
                            <Typography variant="body1" sx={{ color: "#495464" }}>No blueprint added ;-(</Typography>
                        }
                    </Grid>
                </Grid>
            </Paper>
        </Modal >
    );
};

export default BlueprintModal;