import { Typography } from "@mui/material";

const DotMarkerWithNumber = props => {
    return (
        <div style={{ borderRadius: "50%", width: "15px", height: "15px", background: "#202a44", alignContent: "center" }}>
            <Typography variant="body2" align="center" sx={{ color: "#ffffff" }}>{props.index + 1}</Typography>
        </div>
    );
};

export default DotMarkerWithNumber;