import ProjectsTable from "../components/Projects/ProjectsTable";
import { Grid } from "@mui/material";
import TopBar from "../components/Layout/TopBar";
import { useContext, useRef, useEffect, useState } from "react";
import { UserContext } from "../context/UserContext";
import TheSnackbar from "../components/Common/TheSnackbar";
import { useLocation, useNavigate } from "react-router-dom";

const Main = () => {
    const [userContext] = useContext(UserContext);
    const topRef = useRef(null);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState();
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (location.state?.snackbarMessage) {
            setSnackbarMessage("Project deleted");
            setOpenSnackbar(true);
            navigate(".", { replace: true });
        }
    }, [location, navigate]);

    return (
        <Grid container direction="column" sx={{ maxWidth: "1000px" }} ref={topRef}>
            <TheSnackbar open={openSnackbar} setOpen={setOpenSnackbar} message={snackbarMessage} />
            <Grid container item sx={{ justifyContent: "center", paddingBottom: "25px" }}>
                <TopBar
                    title={"Welcome, " + userContext.username.charAt(0).toUpperCase() + userContext.username.slice(1).toLowerCase() + "!"}
                    subtitle="Projects Main"
                />
            </Grid>
            <ProjectsTable topRef={topRef} />
        </Grid>
    );
};

export default Main;