import { Grid, Button, Chip, Autocomplete, TextField, Typography, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { DataGrid, GridToolbarContainer, useGridApiRef } from "@mui/x-data-grid";
import { useEffect, useState, useContext } from "react";
import EditIcon from "@mui/icons-material/Edit";
import EditWorkAreaModal from "./EditWorkAreaModal";
import EditProjectModal from "./EditProjectModal";
import AddWorkAreaModal from "./AddWorkAreaModal";
import DotMarker from "../Common/DotMarker";
import ImageMarker from "react-image-marker";
import ImageModal from "../Common/ImageModal";
import MarkedImageModal from "../Common/MarkedImageModal";
import { useParams } from "react-router-dom";
import TheSnackbar from "../Common/TheSnackbar";
import { UserContext } from "../../context/UserContext";
import { getDaysAgo } from "../../utils/utils";
import PrintIcon from "@mui/icons-material/Print";
import CircleIcon from "@mui/icons-material/Circle";
import BlueprintModal from "./BlueprintModal";
import MapIcon from "@mui/icons-material/Map";
import { axiosInstance } from "../../utils/utils";

const ProjectTable = props => {
    const [openEditWorkAreaModal, setOpenEditWorkAreaModal] = useState(false);
    const [openEditProjectModal, setOpenEditProjectModal] = useState(false);
    const [openAddWorkAreaModal, setOpenAddWorkAreaModal] = useState(false);
    const [openBlueprintModal, setOpenBlueprintModal] = useState(false);
    const [openImageModal, setOpenImageModal] = useState(false);
    const [image, setImage] = useState();
    const [openMarkedImageModal, setOpenMarkedImageModal] = useState(false);
    const [markedImage, setMarkedImage] = useState();
    const params = useParams();
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState();
    const [rowCount, setRowCount] = useState(0);
    const [rowCountState, setRowCountState] = useState(rowCount);
    const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: window.localStorage.getItem("projectPageSize") !== null ? parseInt(window.localStorage.getItem("projectPageSize")) : 15 });
    const [userContext, setUserContext] = useContext(UserContext);
    const [sort, setSort] = useState(window.localStorage.getItem("workAreasSort") !== null ? window.localStorage.getItem("workAreasSort") : "");
    const [search, setSearch] = useState();
    const chips = ["Not Started", "In Progress", "Completed"];
    const [selectedChips, setSelectedChips] = useState(chips.reduce((chips, chip) => ({ ...chips, [chip]: false }), {}));
    const [data, setData] = useState([]);
    const [project, setProject] = useState();
    const [markers, setMarkers] = useState([]);
    const [workAreaId, setWorkAreaId] = useState();
    const [disableModals, setDisableModals] = useState(false);
    const widthToShrinkButtons = 768;
    const apiRef = useGridApiRef();

    const handleClickEntry = (event, _id) => {
        if (event.target.tagName === "INPUT"
            || event.target.tagName === "SPAN"
            || event.target.tagName === "LABEL"
            || event.target.tagName === "IMG"
            || disableModals
            || !userContext.admin) {

            return;
        };

        setWorkAreaId(_id);
        setOpenEditWorkAreaModal(true);
    };

    const handleStatusChange = (event, _id) => {
        const index = data.findIndex(workArea => workArea._id === _id);
        const updatedData = [...data];
        updatedData[index].status = event.target.value;
        setData(updatedData);

        let url = process.env.REACT_APP_API_URL + "/workareas/" + _id;
        const payload = { _id: _id, status: event.target.value };
        const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

        axiosInstance.postForm(url, payload, config)
            .then(res => {
                // Do nothing
            })
            .catch(err => {
                // Do nothing
            });
    };

    const handleClickImage = imgSrc => {
        setImage(imgSrc);
    };

    useEffect(() => {
        if (image) {
            setOpenImageModal(true);
        }
    }, [image]);

    const handleClickMarkedImage = mks => {
        if (!project?.blueprint) return;

        setMarkedImage(getImage(project.blueprint));
        setMarkers(mks);
    };

    useEffect(() => {
        if (markedImage) {
            setOpenMarkedImageModal(true);
        }
    }, [markedImage]);

    const handlePageChange = (newPaginationModel, scrollToTop = true) => {
        setPaginationModel(newPaginationModel);
        window.localStorage.setItem("projectPageSize", newPaginationModel.pageSize);

        if (scrollToTop && props.topRef && props.topRef.current) {
            props.topRef.current.scrollIntoView();
        }
    };

    const setName = props.setName;
    const setDescription = props.setDescription;

    useEffect(() => {
        const fetchWorkAreas = () => {
            if (paginationModel.pageSize === 0) {
                return;
            }

            let url = process.env.REACT_APP_API_URL + "/workareas?project_id=" + params._id + "&page=" + paginationModel.page + "&limit=" + paginationModel.pageSize;
            const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

            if (sort) {
                url += "&sort=" + sort;
            }

            let status = "&status=[";

            for (const [key, value] of Object.entries(selectedChips)) {
                if (value) {
                    status += "\"" + key + "\",";
                }
            }

            if (status !== "&status=[") {
                status = status.substring(0, status.length - 1) + "]";
                url += status;
            }

            if (search) {
                url += "&search=" + search;
            }

            axiosInstance.get(url, config)
                .then(res => {
                    setData(res.data.workAreas);
                    setRowCount(res.data.total);
                })
                .catch(err => {
                    // Do nothing
                });
        };

        const fetchProject = () => {
            let url = process.env.REACT_APP_API_URL + "/projects/" + params._id;
            const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

            axiosInstance.get(url, config)
                .then(res => {
                    setProject(res.data);
                    setName(res.data.name);
                    setDescription(res.data.description);
                })
                .catch(err => {
                    // Do nothing
                });
        };

        fetchProject();
        fetchWorkAreas();
    }, [userContext, setUserContext, sort, selectedChips, search, paginationModel, params._id, setName, setDescription]);

    useEffect(() => {
        setRowCountState(prevRowCountState => rowCount !== undefined ? rowCount : prevRowCountState);
    }, [rowCount, setRowCountState]);

    const handleClickChip = chip => {
        setSelectedChips({ ...selectedChips, [chip]: !selectedChips[chip] });
        handlePageChange({ page: 0, pageSize: paginationModel.pageSize }, false);
    };

    const handleSearch = event => {
        if (event.keyCode === 13) {
            setSearch(event.target.value);
        }
    };

    const getImage = image => {
        return process.env.REACT_APP_API_URL + "/images/" + image + "?token=" + userContext.token;
    };

    const handleSort = event => {
        setSort(event.target.value);

        if (event.target.value) {
            window.localStorage.setItem("workAreasSort", event.target.value);
        } else {
            window.localStorage.removeItem("workAreasSort");
        }
    };

    const rows = data;

    const columns = [{
        field: "_id",
        headerName: "Work Area",
        flex: 1,
        renderCell: params => {
            return (
                <Grid container direction="column" justifyContent="space-between" onClick={event => handleClickEntry(event, params.row._id)}>
                    <Grid container item sx={{ paddingBottom: 2.5 }}>
                        <Grid item xs={8}>
                            <Typography variant="h6" sx={{ cursor: "pointer", width: "fit-content" }}>#{params.row.index + 1} - {params.row.name}</Typography>
                            <Typography sx={{ whiteSpace: "pre-line", cursor: "pointer", width: "fit-content" }}>{params.row.description}</Typography>
                            {params.row.priority &&
                                <Typography sx={{ cursor: "pointer", color: "rgba(0, 0, 0, 0.75)", width: "fit-content", marginTop: "5px", display: "flex" }}>
                                    <CircleIcon sx={{ width: "10px", mr: "8px", color: params.row.priority === "Low" ? "#8bacaa" : (params.row.priority === "Medium" ? "#e38d5b" : "#e76161") }} />
                                    {params.row.priority}
                                </Typography>
                            }
                            {userContext.token && params.row.employees?.length > 0 &&
                                <Typography sx={{ cursor: "pointer", color: "rgba(0, 0, 0, 0.75)", width: "fit-content", marginTop: "5px" }}>
                                    <b>Assigned to: </b>
                                    {params.row.employees.map((employee, index) => {
                                        if (index > 0) {
                                            return ", " + employee.name;
                                        } else {
                                            return employee.name;
                                        }
                                    })}
                                </Typography>
                            }
                            {(params.row.startDate || params.row.endDate) &&
                                <Typography sx={{ cursor: "pointer", width: "fit-content", marginTop: "5px" }}>
                                    {params.row.startDate && <><b>Start date:</b> {new Date(params.row.startDate).toLocaleDateString()}</>}
                                    {params.row.startDate && params.row.endDate && <><b> | </b></>}
                                    {params.row.endDate && <><b>End date:</b> {new Date(params.row.endDate).toLocaleDateString()}</>}
                                </Typography>
                            }
                            <Typography variant="body2" sx={{ color: "rgba(0, 0, 0, 0.5)", mt: "10px" }}>
                                Updated {getDaysAgo(params.row.updatedAt)} {userContext.token && params.row.updatedBy ? " by " + params.row.updatedBy.charAt(0).toUpperCase() + params.row.updatedBy.slice(1).toLowerCase() : null}
                            </Typography>
                            <Typography variant="body2" sx={{ color: "rgba(0, 0, 0, 0.5)" }}>
                                Created {userContext.token && params.row.createdBy ? "by " + params.row.createdBy.charAt(0).toUpperCase() + params.row.createdBy.slice(1).toLowerCase() + " on " : null} {new Date(params.row.createdAt).toDateString()}
                            </Typography>
                        </Grid>
                        {userContext.admin ?
                            <Grid container item xs={4} justifyContent="flex-end">
                                <FormControl fullWidth size="small" sx={{ width: "130px" }}>
                                    <InputLabel>Status</InputLabel>
                                    <Select
                                        value={params.row.status ? params.row.status : ""}
                                        label="Status"
                                        onChange={event => handleStatusChange(event, params.row._id)}
                                        onClose={() => setDisableModals(false)}
                                        onOpen={() => setDisableModals(true)}
                                    >
                                        <MenuItem value={""} sx={{ color: "rgba(0, 0, 0, 0.4)" }}>None</MenuItem>
                                        <MenuItem value={"Not Started"}>Not Started</MenuItem>
                                        <MenuItem value={"In Progress"}>In Progress</MenuItem>
                                        <MenuItem value={"Completed"}>Completed</MenuItem>
                                    </Select>
                                </FormControl>
                                {params.row.status &&
                                    <Typography sx={{ cursor: "pointer", color: "rgba(0, 0, 0, 0.75)", display: "none" }}>
                                        <b>Status</b>: {params.row.status}
                                    </Typography>
                                }
                            </Grid>
                            :
                            <Grid container item xs={4} justifyContent="flex-end">
                                {params.row.status &&
                                    <Typography sx={{ cursor: "pointer", color: "rgba(0, 0, 0, 0.75)" }}>
                                        <b>Status</b>: {params.row.status}
                                    </Typography>
                                }
                            </Grid>
                        }
                    </Grid>
                    <Grid container item spacing={2.5} sx={{ paddingBottom: 2.5 }}>
                        {project?.blueprint && params.row.markers?.length > 0 &&
                            <Grid item className="blueprint">
                                <div onClick={() => handleClickMarkedImage(params.row.markers)} style={{ width: "200px", height: "200px", overflow: "hidden", cursor: "pointer" }}>
                                    <div style={{ position: "relative", transform: `translateY(clamp(-1 * calc(100% - 210px), -${params.row.markers[0].top - 5}%, 0px))` }}>
                                        <ImageMarker
                                            src={getImage(process.env.REACT_APP_THUMBNAIL_PREFIX + project.blueprint)}
                                            markers={params.row.markers}
                                            markerComponent={DotMarker}
                                        />
                                    </div>
                                </div>
                            </Grid>
                        }
                        {params.row.images &&
                            params.row.images.map((img, index) => {
                                return (
                                    <Grid item key={index}>
                                        <img
                                            src={getImage(process.env.REACT_APP_THUMBNAIL_PREFIX + img)}
                                            alt=""
                                            width="200px"
                                            height="200px"
                                            style={{ objectFit: "cover", cursor: "pointer" }}
                                            onClick={() => handleClickImage(getImage(img))}
                                        />
                                    </Grid>
                                );
                            })
                        }
                    </Grid>
                </Grid>
            );
        }
    }];

    const CustomToolbar = () =>
        <GridToolbarContainer
            sx={{
                "@media print": {
                    "& .css-13oo8r0-MuiGrid-root": { display: "none" },
                    "& .css-1ct0wh-MuiGrid-root": { display: "none" },
                    "& .css-blursa-MuiGrid-root": { display: "flex" },
                    "& .MuiTypography-root": { fontFamily: "Arial" }
                }
            }}
        >
            <Grid container spacing={2.5} sx={{ marginBottom: "25px", display: "none" }}>
                <Grid item>
                    <Typography variant="h4">{project?.name}</Typography>
                    <Typography variant="h6">{project?.description}</Typography>
                </Grid>
            </Grid>
            <Grid container spacing={2.5} alignItems="center" sx={{ marginBottom: "25px" }}>
                <Grid item sx={{ flexGrow: 1 }}>
                    <Autocomplete
                        options={[]}
                        renderInput={params => (<TextField {...params} label="Search" onKeyDown={handleSearch} />)}
                        freeSolo
                        value={search}
                        sx={{ width: 300 }}
                    />
                </Grid>
                <Grid item>
                    <Button
                        size="medium"
                        sx={{ marginRight: 1.75 }}
                        onClick={() => apiRef.current.exportDataAsPrint({ hideFooter: true })}
                    >
                        {window.innerWidth > widthToShrinkButtons ?
                            <>
                                <span style={{ marginLeft: "-4px", marginRight: "8px", display: "inherit" }}><PrintIcon sx={{ fontSize: 20 }} /></span>
                                Print
                            </>
                            :
                            <PrintIcon sx={{ fontSize: 20 }} />
                        }
                    </Button>
                    {userContext.admin &&
                        <Button
                            size="medium"
                            sx={{ backgroundColor: "#8da9b6", color: "#302b27", "&:hover": { backgroundColor: "#8da9b6" }, mb: 1.25, mt: 1.25, mr: 1.75 }}
                            onClick={() => setOpenEditProjectModal(true)}
                        >
                            {window.innerWidth > widthToShrinkButtons ?
                                <>
                                    <span style={{ marginLeft: "-4px", marginRight: "8px", display: "inherit" }}><EditIcon sx={{ fontSize: 20 }} /></span>
                                    Project
                                </>
                                :
                                <EditIcon sx={{ fontSize: 20 }} />
                            }
                        </Button>
                    }
                    {project?.blueprint &&
                        <Button
                            size="medium"
                            sx={{ mr: 1.75 }}
                            onClick={() => setOpenBlueprintModal(true)}
                        >
                            {window.innerWidth > widthToShrinkButtons ?
                                <>
                                    <span style={{ marginLeft: "-4px", marginRight: "8px", display: "inherit" }}><MapIcon sx={{ fontSize: 20 }} /></span>
                                    Overview
                                </>
                                :
                                <MapIcon sx={{ fontSize: 20 }} />
                            }
                        </Button>
                    }
                    {userContext.admin &&
                        <Button
                            size="medium"
                            sx={{ backgroundColor: "#1b5e7d", color: "#d9d9d9", "&:hover": { backgroundColor: "#1b5e7d" }, mb: 1.25, mt: 1.25 }}
                            onClick={() => setOpenAddWorkAreaModal(true)}
                        >
                            {window.innerWidth > widthToShrinkButtons ?
                                <>
                                    <span style={{ marginLeft: "-4px", marginRight: "8px", display: "inherit" }}><AddIcon sx={{ fontSize: 20 }} /></span>
                                    Work
                                </>
                                :
                                <AddIcon sx={{ fontSize: 20 }} />
                            }
                        </Button>
                    }
                </Grid>
            </Grid>
            <Grid container item spacing={2.5} alignItems="center" sx={{ marginBottom: "25px" }}>
                <Grid container item spacing={1.5} xs={8}>
                    {chips.map((chip, index) => {
                        return (
                            <Grid item key={index}>
                                <Chip label={chip} variant={selectedChips[chip] ? "filled" : "outlined"} onClick={() => handleClickChip(chip)} />
                            </Grid>
                        );
                    })}
                </Grid>
                <Grid container item xs={4} justifyContent="flex-end">
                    <FormControl fullWidth size="small" sx={{ maxWidth: "200px" }}>
                        <InputLabel>Sort by</InputLabel>
                        <Select
                            value={sort}
                            label="Sort by"
                            onChange={handleSort}
                        >
                            <MenuItem value={"updatedAt:desc"}>Last updated</MenuItem>
                            <MenuItem value={"index:asc"}>Date added</MenuItem>
                            <MenuItem value={"name:asc"}>Name</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </GridToolbarContainer >

    return (
        <Grid item>
            <TheSnackbar open={openSnackbar} setOpen={setOpenSnackbar} message={snackbarMessage} />
            <DataGrid
                rows={rows}
                columns={columns}
                getRowHeight={() => "auto"}
                autoHeight
                disableRowSelectionOnClick
                disableColumnMenu
                getRowId={row => row._id}
                slots={{
                    toolbar: CustomToolbar,
                    noRowsOverlay: () => {
                        return <Typography variant="body1" align="center" sx={{ color: "#495464" }}>No work was added ;-(</Typography>;
                    }
                }}
                pageSizeOptions={[15, 30, 50, 100]}
                paginationMode="server"
                paginationModel={paginationModel}
                onPaginationModelChange={handlePageChange}
                rowCount={rowCountState}
                apiRef={apiRef}
                sx={{
                    fontSize: 16,
                    border: 0,
                    "& .MuiDataGrid-cell": {
                        paddingTop: "12px",
                        paddingBottom: "12px",
                        border: 0,
                        "&:focus-within, &:focus": { outline: "none" }
                    },
                    "& .MuiDataGrid-footerContainer": { border: 0 },
                    "& .MuiDataGrid-columnHeaders": { display: "none" },
                    "& .MuiDataGrid-row:hover": { borderRadius: "0px" },
                    "& .MuiDataGrid-row": { borderBottom: "1px solid rgba(0, 0, 0, 0.1)" },
                    "@media print": {
                        fontFamily: "Arial",
                        color: "#000000",
                        display: "table", // Fit columns onto page and avoid break inside rows
                        "& .MuiDataGrid-virtualScroller": { display: "table", overflow: "visible" },
                        "& .blueprint": {
                            display: "none"
                        },
                        "& .image-marker__marker": {
                            "& div": {
                                boxShadow: "inset 0 0 0 15px bslack"
                            }
                        },
                        "& .css-v39gqh-MuiFormControl-root": {
                            display: "none"
                        },
                        "& .css-j30ifz-MuiTypography-root": {
                            display: "flex"
                        }
                    }
                }}
            />
            {openEditWorkAreaModal &&
                <EditWorkAreaModal
                    openModal={openEditWorkAreaModal}
                    setOpenModal={setOpenEditWorkAreaModal}
                    _id={workAreaId}
                    projectId={params._id}
                    setSnackbarMessage={setSnackbarMessage}
                    setOpenSnackbar={setOpenSnackbar}
                />
            }
            {openEditProjectModal &&
                <EditProjectModal
                    openModal={openEditProjectModal}
                    setOpenModal={setOpenEditProjectModal}
                    _id={params._id}
                    setSnackbarMessage={setSnackbarMessage}
                    setOpenSnackbar={setOpenSnackbar}
                />
            }
            {openAddWorkAreaModal &&
                <AddWorkAreaModal
                    openModal={openAddWorkAreaModal}
                    setOpenModal={setOpenAddWorkAreaModal}
                    projectId={params._id}
                    setSnackbarMessage={setSnackbarMessage}
                    setOpenSnackbar={setOpenSnackbar}
                />
            }
            {openImageModal &&
                <ImageModal
                    openModal={openImageModal}
                    setOpenModal={setOpenImageModal}
                    image={image}
                    setImage={setImage}
                />
            }
            {openMarkedImageModal &&
                <MarkedImageModal
                    openModal={openMarkedImageModal}
                    setOpenModal={setOpenMarkedImageModal}
                    image={markedImage}
                    setImage={setMarkedImage}
                    markers={markers}
                />
            }
            {openBlueprintModal &&
                <BlueprintModal
                    openModal={openBlueprintModal}
                    setOpenModal={setOpenBlueprintModal}
                    setSnackbarMessage={setSnackbarMessage}
                    setOpenSnackbar={setOpenSnackbar}
                    project={project}
                />
            }
        </Grid>
    );
};

export default ProjectTable;