import { Grid, Paper, Button, TextField, Typography, Modal, IconButton, FormControlLabel, Checkbox, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useState, useEffect, useContext, useCallback } from "react";
import ErrorBox from "../Common/ErrorBox";
import AddImageModal from "./AddImageModal";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CloseIcon from "@mui/icons-material/Close";
import ImageMarker from "react-image-marker";
import DotMarker from "../Common/DotMarker";
import { UserContext } from "../../context/UserContext";
import CloseDialog from "../Common/CloseDialog";
import CircleIcon from "@mui/icons-material/Circle";
import { axiosInstance } from "../../utils/utils";

const AddWorkAreaModal = props => {
    const [disableSubmitButton, setDisableSubmitButton] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const detailsTextFieldParams = { type: "text", fullWidth: true, variant: "outlined", onChange: event => handleChange(event.target.name, event.target.value) };
    const [images, setImages] = useState([]);
    const [image, setImage] = useState();
    const [imageIndex, setImageIndex] = useState();
    const [details, setDetails] = useState({ name: "", description: "", startDate: null, endDate: null, priority: "" });
    const [openAddImageModal, setOpenAddImageModal] = useState(false);
    const gridItemParams = { sx: { width: "50%" } };
    const [processedImage, setProcessedImage] = useState();
    const [markers, setMarkers] = useState([]);
    const [blueprint, setBlueprint] = useState();
    const [userContext, setUserContext] = useContext(UserContext);
    const [modified, setModified] = useState(false);
    const [openCloseDialog, setOpenCloseDialog] = useState(false);
    const [employees, setEmployees] = useState([]);
    const employeesTextFieldParams = { type: "text", fullWidth: true, variant: "outlined" };
    const [separateEntries, setSeparateEntries] = useState(false);
    const mobileWidth = 430;

    const handleChange = (name, value) => {
        setDetails(pairs => ({ ...pairs, [name]: value }));
        setModified(true);
    };

    const handleRemoveImage = index => {
        let updatedImages = [...images];
        updatedImages.splice(index, 1);
        setImages(updatedImages);

        let updatedDetailsImages = [...details.images];
        updatedDetailsImages.splice(index, 1);
        setDetails(details => ({ ...details, images: updatedDetailsImages }));

        setModified(true);
    };

    const handleClose = () => props.setOpenModal(false);

    useEffect(() => {
        const fieldsNotEmpty = () => {
            if (details.time && isNaN(details.time)) {
                return false;
            }

            if (details.expense && isNaN(details.expense)) {
                return false;
            }

            return details.name && employees.every(employee => employee.name);
        };

        setDisableSubmitButton(!fieldsNotEmpty());
    }, [details, employees]);

    const handleAddImage = (event, value) => {
        let updatedDetailsImages = details.images ? details.images : [];

        for (let i = 0; i < event.target.files.length; i++) {
            updatedDetailsImages.push(event.target.files[i]);

            setDetails(details => ({ ...details, images: updatedDetailsImages }));
            setImages(images => [...images, URL.createObjectURL(event.target.files[i])]);
        }

        setModified(true);
    };

    const handleImageClick = index => {
        setImageIndex(index);
        setImage(images[index]);
        setOpenAddImageModal(true);
    };

    const handleDateChange = (name, value) => {
        if (!Date.parse(value)) {
            delete details[name];
        } else {
            handleChange(name, value);
        }

        setModified(true);
    };

    useEffect(() => {
        if (processedImage) {
            fetch(processedImage)
                .then(res => {
                    res.blob().then(imgBlob => {
                        const img = new File([imgBlob], "image", { type: "image/jpeg" });

                        let updatedDetailsImages = details.images ? details.images : [];
                        updatedDetailsImages[imageIndex] = img;
                        setDetails(details => ({ ...details, images: updatedDetailsImages }));

                        let updatedImages = images ? images : [];
                        updatedImages[imageIndex] = processedImage;
                        setImages(updatedImages);

                        setProcessedImage();
                    });
                });

            setOpenAddImageModal(false);
        }
    }, [processedImage, details.images, imageIndex, images]);

    useEffect(() => {
        if (markers.length === 0) {
            setDetails(details => ({ ...details, markers: [] }));
        } else {
            setDetails(details => ({ ...details, markers: markers }));
        }
    }, [markers]);

    const getImage = useCallback(image => {
        return process.env.REACT_APP_API_URL + "/images/" + image + "?token=" + userContext.token;
    }, [userContext.token]);

    const handleSubmit = event => {
        event.preventDefault();
        setDisableSubmitButton(true);

        let url = process.env.REACT_APP_API_URL + "/workareas?project_id=" + props.projectId;
        const payload = details;
        const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

        if (details.markers && details.markers.length === 0) {
            setDetails(details => ({ ...details, markers: "" }));
        }

        if (!separateEntries || !details.images || details.images.length < 2) {
            axiosInstance.postForm(url, payload, config)
                .then(res => {
                    props.setOpenModal(false);
                    props.setSnackbarMessage("Work area added");
                    props.setOpenSnackbar(true);
                    setUserContext(userContext => ({ ...userContext, refresh: !userContext.refresh }));
                })
                .catch(err => {
                    setErrorMessage(err.response.data);
                    setShowErrorMessage(true);
                    setDisableSubmitButton(false);
                });
        } else {
            let promises = [];

            details.images.forEach(image => {
                const payload = { ...details, images: [image] };
                promises.push(axiosInstance.postForm(url, payload, config));
            });

            Promise.all(promises)
                .then(res => {
                    props.setOpenModal(false);
                    props.setSnackbarMessage("Work areas added");
                    props.setOpenSnackbar(true);
                    setUserContext(userContext => ({ ...userContext, refresh: !userContext.refresh }));
                })
                .catch(err => {
                    setErrorMessage(err.response.data);
                    setShowErrorMessage(true);
                    setDisableSubmitButton(false);
                });
        }
    };

    useEffect(() => {
        const fetchBlueprint = () => {
            let url = process.env.REACT_APP_API_URL + "/projects/" + props.projectId;
            const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

            axiosInstance.get(url, config)
                .then(res => {
                    if (res.data.blueprint) {
                        setBlueprint(getImage(res.data.blueprint));
                    }
                })
                .catch(err => {
                    // Do nothing
                });
        };

        fetchBlueprint();
    }, [props.projectId, userContext, setUserContext, getImage]);

    const handleCloseWithChange = () => {
        if (modified) {
            setOpenCloseDialog(true);
        } else {
            props.setOpenModal(false);
        }
    };

    const handleAddEmployee = () => {
        setEmployees(employees => [...employees, { name: "" }]);
        setModified(true);
    };

    const handleEmployeeChange = (name, value, index) => {
        let updatedEmployees = [...employees];
        updatedEmployees[index][name] = value;
        setEmployees(updatedEmployees);
        setModified(true);
    };

    const handleRemoveEmployee = (index) => {
        let updatedEmployees = [...employees];
        updatedEmployees.splice(index, 1);
        setEmployees(updatedEmployees);
        setModified(true);
    };

    useEffect(() => {
        setDetails(details => ({ ...details, employees: employees }));
    }, [employees]);

    return (
        <>
            <Modal open={props.openModal} onClose={handleCloseWithChange}>
                <Paper
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        maxWidth: 800,
                        maxHeight: 1000,
                        width: "90%",
                        height: "90%",
                        bgcolor: "#d9d9d9",
                        overflow: "auto"
                    }}
                >
                    <Grid container item justifyContent="flex-end" sx={{ padding: "2.5% 2.5% 2.5% 0" }}>
                        <IconButton onClick={handleCloseWithChange}><CloseIcon /></IconButton>
                    </Grid>
                    <div style={{ padding: "0% 6% 6% 6%" }}>
                        <Typography variant="h5" sx={{ mb: 0.5 }}>Add Work</Typography>
                        <Typography variant="body1" sx={{ mb: 2.5, color: "rgba(49, 54, 64, 0.6)" }}>Create a new work area</Typography>
                        <form onSubmit={handleSubmit}>
                            <Grid container direction="column" spacing={2.5}>
                                <Grid container item>
                                    <fieldset style={{ fontSize: 20, border: "1px solid rgba(0, 0, 0, 0.2)", borderRadius: 5, width: "100%" }}>
                                        <legend>Details</legend>
                                        <Grid container item direction="column" spacing={2.5} sx={{ padding: "3%" }}>
                                            <Grid item container spacing={2.5}>
                                                <Grid item {...gridItemParams}>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DatePicker slotProps={{ field: { clearable: true, onClear: () => setDetails({ ...details, startDate: null }) } }} label="Start date" value={details.startDate} onChange={value => handleDateChange("startDate", value)} sx={{ width: "100%" }} />
                                                    </LocalizationProvider>
                                                </Grid>
                                                <Grid item {...gridItemParams}>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DatePicker slotProps={{ field: { clearable: true, onClear: () => setDetails({ ...details, endDate: null }) } }} label="End date" value={details.endDate} onChange={value => handleDateChange("endDate", value)} sx={{ width: "100%" }} />
                                                    </LocalizationProvider>
                                                </Grid>
                                            </Grid>
                                            <Grid item><TextField {...detailsTextFieldParams} name="name" label="Name" value={details.name} /></Grid>
                                            <Grid item>
                                                <TextField {...detailsTextFieldParams} name="description" label="Description" multiline rows={4} value={details.description} />
                                            </Grid>
                                        </Grid>
                                    </fieldset>
                                </Grid>
                                <Grid container item>
                                    <fieldset style={{ fontSize: 20, border: "1px solid rgba(0, 0, 0, 0.2)", borderRadius: 5, width: "100%" }}>
                                        <legend>Priority</legend>
                                        <Grid container item direction="column" spacing={2.5} sx={{ padding: "3%" }}>
                                            <Grid item>
                                                <ToggleButtonGroup
                                                    exclusive
                                                    onChange={(event, selectedPriority) => {
                                                        if (selectedPriority !== null) {
                                                            setDetails({ ...details, priority: selectedPriority });
                                                        }
                                                    }}
                                                    value={details.priority}
                                                    sx={{ "& .Mui-selected": { color: "rgba(0, 0, 0, 0.65)" } }}
                                                    size={window.innerWidth <= mobileWidth ? "small" : "medium"}
                                                >
                                                    <ToggleButton value="">None</ToggleButton>
                                                    <ToggleButton value="Low"><CircleIcon sx={{ width: "10px", mr: window.innerWidth <= mobileWidth ? "5px" : "8px", color: "#8bacaa" }} />Low</ToggleButton>
                                                    <ToggleButton value="Medium"><CircleIcon sx={{ width: "10px", mr: window.innerWidth <= mobileWidth ? "5px" : "8px", color: "#e38d5b" }} />{window.innerWidth <= mobileWidth ? "Med" : "Medium"}</ToggleButton>
                                                    <ToggleButton value="High"><CircleIcon sx={{ width: "10px", mr: window.innerWidth <= mobileWidth ? "5px" : "8px", color: "#e76161" }} />High</ToggleButton>
                                                </ToggleButtonGroup>
                                            </Grid>
                                        </Grid>
                                    </fieldset>
                                </Grid>
                                {userContext.token &&
                                    <Grid container item>
                                        <fieldset style={{ fontSize: 20, border: "1px solid rgba(0, 0, 0, 0.2)", borderRadius: 5, width: "100%" }}>
                                            <legend>Assign</legend>
                                            <Grid container item direction="column" spacing={2.5} sx={{ padding: "3%" }}>
                                                <Grid container item spacing={2.5}>
                                                    {employees.length > 0 ?
                                                        employees.map((employee, index) => {
                                                            return (
                                                                <Grid container item spacing={2.5} key={index}>
                                                                    <Grid container item xs={6} sx={{ minWidth: "200px" }}>
                                                                        <TextField
                                                                            {...employeesTextFieldParams}
                                                                            name="name"
                                                                            label="Name"
                                                                            value={String(employee.name)}
                                                                            onChange={event => handleEmployeeChange(event.target.name, event.target.value, index)}
                                                                        />
                                                                    </Grid>
                                                                    <Grid container item alignItems="center" xs={2}>
                                                                        <IconButton tabIndex={-1} onClick={() => handleRemoveEmployee(index)}><CloseIcon /></IconButton>
                                                                    </Grid>
                                                                </Grid>
                                                            )
                                                        })
                                                        :
                                                        <Grid item>
                                                            <Typography variant="body1" sx={{ color: "#495464" }}>No employee was added ;-(</Typography>
                                                        </Grid>
                                                    }
                                                </Grid>
                                                <Grid container item>
                                                    <Grid container item><Button onClick={handleAddEmployee}>Add Employee</Button></Grid>
                                                </Grid>
                                            </Grid>
                                        </fieldset>
                                    </Grid>
                                }
                                {blueprint &&
                                    <Grid container item>
                                        <fieldset style={{ fontSize: 20, border: "1px solid rgba(0, 0, 0, 0.2)", borderRadius: 5, width: "100%" }}>
                                            <legend>Mark areas</legend>
                                            <Grid container item direction="column" spacing={2.5} sx={{ padding: "3%" }}>
                                                <Grid item>
                                                    <Typography variant="body1" sx={{ color: "#495464" }}>Click on the blueprint to place a mark</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <ImageMarker
                                                        src={blueprint}
                                                        markers={markers}
                                                        onAddMarker={marker => {
                                                            setMarkers(markers => [...markers, marker]);
                                                            setModified(true);
                                                        }}
                                                        markerComponent={DotMarker}
                                                    />
                                                </Grid>
                                                <Grid container item spacing={2.5}>
                                                    <Grid item>
                                                        <Button
                                                            onClick={() => {
                                                                setMarkers([]);
                                                                setModified(true);
                                                            }}
                                                            disabled={markers?.length === 0}
                                                            sx={{ backgroundColor: "#8da9b6", color: "#d9d9d9", "&:hover": { backgroundColor: "#8da9b6" } }}
                                                        >
                                                            Clear
                                                        </Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button
                                                            onClick={() => {
                                                                setMarkers(markers => markers.slice(0, -1));
                                                                setModified(true);
                                                            }}
                                                            disabled={markers?.length === 0}
                                                            sx={{ backgroundColor: "#302b27", color: "#d9d9d9", "&:hover": { backgroundColor: "#302b27" } }}
                                                        >
                                                            Undo
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </fieldset>
                                    </Grid>
                                }
                                <Grid container item>
                                    <fieldset style={{ fontSize: 20, border: "1px solid rgba(0, 0, 0, 0.2)", borderRadius: 5, width: "100%" }}>
                                        <legend>Images</legend>
                                        <Grid container item direction="column" spacing={2.5} sx={{ padding: "3%" }}>
                                            <Grid container item>
                                                {images.length > 0 ?
                                                    images.map((image, index) => {
                                                        return (
                                                            <Grid container item key={index} spacing={2.5}>
                                                                <Grid item xs={6}>
                                                                    <img
                                                                        src={image}
                                                                        alt=""
                                                                        width="100%"
                                                                        style={{ objectFit: "cover", aspectRatio: 1, cursor: "pointer" }}
                                                                        onClick={() => handleImageClick(index)}
                                                                    />
                                                                </Grid>
                                                                <Grid item container alignContent="center" xs={2}>
                                                                    <IconButton tabIndex={-1} onClick={() => handleRemoveImage(index)}><CloseIcon /></IconButton>
                                                                </Grid>
                                                            </Grid>
                                                        )
                                                    })
                                                    :
                                                    <Grid item>
                                                        <Typography variant="body1" sx={{ color: "#495464" }}>No images added for this work area ;-(</Typography>
                                                    </Grid>
                                                }
                                            </Grid>
                                            <Grid container item>
                                                <label>
                                                    <input hidden type="file" multiple accept="image/jpeg" onChange={handleAddImage} onClick={event => event.target.value = null} />
                                                    <Button color="secondary" variant="contained" component="span">Add Image</Button>
                                                </label>
                                            </Grid>
                                        </Grid>
                                    </fieldset>
                                </Grid>
                                <Grid container item>
                                    <fieldset style={{ fontSize: 20, border: "1px solid rgba(0, 0, 0, 0.2)", borderRadius: 5, width: "100%" }}>
                                        <legend>Additonal Options</legend>
                                        <Grid container item direction="column" spacing={2.5} sx={{ padding: "3%" }}>
                                            <Grid item>
                                                <FormControlLabel checked={separateEntries} control={<Checkbox />} onChange={event => setSeparateEntries(!separateEntries)} label="Ungroup images" />
                                            </Grid>
                                        </Grid>
                                    </fieldset>
                                </Grid>
                                <ErrorBox showErrorMessage={showErrorMessage} errorMessage={errorMessage} />
                                <Grid container item justifyContent="flex-end">
                                    <Grid item sx={{ mr: 2.5 }}>
                                        <Button variant="outlined" onClick={handleClose}>Cancel</Button>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            type="submit"
                                            disabled={disableSubmitButton}
                                            sx={{ backgroundColor: "#1b5e7d", color: "#d9d9d9", "&:hover": { backgroundColor: "#1b5e7d" } }}
                                        >
                                            Submit
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {openCloseDialog && <CloseDialog open={openCloseDialog} setOpen={setOpenCloseDialog} handleClose={handleClose} />}
                        </form>
                    </div>
                </Paper>
            </Modal>
            {openAddImageModal &&
                <AddImageModal
                    openModal={openAddImageModal}
                    setOpenModal={setOpenAddImageModal}
                    image={image}
                    processedImage={processedImage}
                    setProcessedImage={setProcessedImage}
                />
            }
        </>
    );
};

export default AddWorkAreaModal;