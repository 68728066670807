import CropImage from "./CropImage";
import MarkImage from "./MarkImage";
import { useState, useEffect, useRef } from "react";
import { Modal, Paper, Grid } from "@mui/material";

const AddImageModal = props => {
    const [openCropImageComponent, setOpenCropImageComponent] = useState(true);
    const [croppedImage, setCroppedImage] = useState();
    const [openMarkImageComponent, setOpenMarkImageComponent] = useState(false);
    const [markedImage, setMarkedImage] = useState();
    const paperRef = useRef(null)

    useEffect(() => {
        if (croppedImage) {
            setOpenCropImageComponent(false);
            setOpenMarkImageComponent(true);
        }
    }, [croppedImage]);

    const setProcessedImage = props.setProcessedImage;

    useEffect(() => {
        if (markedImage) {
            setProcessedImage(markedImage);
        }
    }, [markedImage, setProcessedImage]);

    return (
        <Modal open={props.openModal} onClose={() => props.setOpenModal(false)}>
            <Paper
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    maxWidth: 800,
                    maxHeight: 1000,
                    width: "90%",
                    height: "90%",
                    bgcolor: "#d9d9d9",
                    padding: "5%",
                    overflow: "auto"
                }}
                ref={paperRef}
            >
                <Grid container>
                    {openCropImageComponent &&
                        <CropImage
                            setOpenModal={props.setOpenModal}
                            image={props.image}
                            croppedImage={croppedImage}
                            setCroppedImage={setCroppedImage}
                            paperRef={paperRef}
                        />
                    }
                    {openMarkImageComponent &&
                        <MarkImage
                            setOpenMarkImageComponent={setOpenMarkImageComponent}
                            setOpenCropImageComponent={setOpenCropImageComponent}
                            image={croppedImage}
                            markedImage={markedImage}
                            setMarkedImage={setMarkedImage}
                            setImage={setCroppedImage}
                            paperRef={paperRef}
                        />
                    }
                </Grid>
            </Paper>
        </Modal>
    );
};

export default AddImageModal;