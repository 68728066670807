import { Modal, Paper, Grid, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const ImageModal = props => {
    const handleClose = () => {
        props.setImage();
        props.setOpenModal(false);
    };

    return (
        <Modal open={props.openModal} onClose={handleClose}>
            <Paper
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    maxWidth: 800,
                    maxHeight: 1000,
                    width: "90%",
                    height: "90%",
                    bgcolor: "#d9d9d9",
                    overflow: "auto"
                }}
            >
                <Grid container item justifyContent="flex-end" sx={{ padding: "2.5% 2.5% 2.5% 0" }}>
                    <IconButton onClick={handleClose}><CloseIcon /></IconButton>
                </Grid>
                <Grid container item alignItems="center" sx={{ padding: "0% 6% 6% 6%", minHeight: "90%" }}>
                    <img src={props.image} alt="" width="100%" />
                </Grid>
            </Paper>
        </Modal >
    );
};

export default ImageModal;