import { Grid, Paper, Button, TextField, Typography, Modal, IconButton } from "@mui/material";
import ImageIcon from "@mui/icons-material/Image";
import { useState, useEffect, useContext } from "react";
import ErrorBox from "../Common/ErrorBox";
import { UserContext } from "../../context/UserContext";
import CloseIcon from "@mui/icons-material/Close";
import CloseDialog from "../Common/CloseDialog";
import { axiosInstance } from "../../utils/utils";

const AddProjectModal = props => {
    const [disableSubmitButton, setDisableSubmitButton] = useState(false);
    const [disableClearImageButton, setDisableClearImageButton] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const detailsTextFieldParams = { type: "text", fullWidth: true, variant: "outlined", onChange: event => handleChange(event.target.name, event.target.value) };
    const [image, setImage] = useState();
    const [details, setDetails] = useState({ name: "", description: "" });
    const [blueprint, setBlueprint] = useState();
    const [disableClearBlueprintButton, setDisableClearBlueprintButton] = useState(false);
    const [userContext, setUserContext] = useContext(UserContext);
    const [modified, setModified] = useState(false);
    const [openCloseDialog, setOpenCloseDialog] = useState(false);

    const handleChange = (name, value) => {
        setDetails(pairs => ({ ...pairs, [name]: value }));
        setModified(true);
    };

    const handleImageChange = (event, value) => {
        handleChange("image", event.target.files[0]);
        setImage(URL.createObjectURL(event.target.files[0]));
        setModified(true);
    };

    const handleRemoveImage = () => {
        handleChange("image", "");
        setImage();
        setModified(true);
    };

    const handleClose = () => props.setOpenModal(false);

    useEffect(() => {
        const fieldsNotEmpty = () => {
            return details.name;
        };

        setDisableSubmitButton(!fieldsNotEmpty());
        setDisableClearImageButton(!details.image);
        setDisableClearBlueprintButton(!details.blueprint);
    }, [details]);

    const handleBlueprintChange = (event, value) => {
        handleChange("blueprint", event.target.files[0]);
        setBlueprint(URL.createObjectURL(event.target.files[0]));
        setModified(true);
    };

    const handleRemoveBlueprint = () => {
        handleChange("blueprint", "");
        setBlueprint();
        setModified(true);
    };

    const handleSubmit = event => {
        event.preventDefault();
        setDisableSubmitButton(true);

        const url = process.env.REACT_APP_API_URL + "/projects";
        const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };
        const payload = details;

        axiosInstance.postForm(url, payload, config)
            .then(res => {
                props.setOpenModal(false);
                props.setSnackbarMessage("Project added");
                props.setOpenSnackbar(true);
                setUserContext(userContext => ({ ...userContext, refresh: !userContext.refresh }));
            })
            .catch(err => {
                setErrorMessage(err.response.data);
                setShowErrorMessage(true);
                setDisableSubmitButton(false);
            });
    };

    const handleCloseWithChange = () => {
        if (modified) {
            setOpenCloseDialog(true);
        } else {
            props.setOpenModal(false);
        }
    };

    return (
        <Modal open={props.openModal} onClose={handleCloseWithChange}>
            <Paper
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    maxWidth: 825,
                    maxHeight: 700,
                    width: "90%",
                    height: "90%",
                    bgcolor: "#d9d9d9",
                    overflow: "auto"
                }}
            >
                <Grid container item justifyContent="flex-end" sx={{ padding: "2.5% 2.5% 2.5% 0" }}>
                    <IconButton onClick={handleCloseWithChange}><CloseIcon /></IconButton>
                </Grid>
                <div style={{ padding: "0% 6% 6% 6%" }}>
                    <Typography variant="h5" sx={{ mb: 0.5 }}>Add Project</Typography>
                    <Typography variant="body1" sx={{ mb: 2.5, color: "rgba(49, 54, 64, 0.6)" }}>Create a new project</Typography>
                    <form onSubmit={handleSubmit}>
                        <Grid container direction="column" spacing={2.5}>
                            <Grid container item>
                                <fieldset style={{ fontSize: 20, border: "1px solid rgba(0, 0, 0, 0.2)", borderRadius: 5, width: "100%" }}>
                                    <legend>Details</legend>
                                    <Grid container item spacing={4.5} justifyContent="center" sx={{ padding: "3%" }}>
                                        <Grid container item direction="column" spacing={2.5} xs={6} sx={{ minWidth: "300px" }}>
                                            <Grid container item justifyContent="center">
                                                <div style={{ width: "100%", height: "100%", maxWidth: "300px", aspectRatio: 1, borderRadius: "5px" }}>
                                                    {image ?
                                                        <img src={image} alt="" style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: "5px" }} />
                                                        :
                                                        <ImageIcon viewBox="3 3 18 18" sx={{ width: "100%", height: "100%" }} />
                                                    }
                                                </div>
                                            </Grid>
                                            <Grid container item justifyContent="center" spacing={2.5}>
                                                <Grid item>
                                                    <label>
                                                        <input hidden type="file" accept="image/*" onChange={handleImageChange} onClick={event => event.target.value = null} />
                                                        <Button color="secondary" variant="contained" component="span">Upload</Button>
                                                    </label>
                                                </Grid>
                                                <Grid item>
                                                    <Button
                                                        onClick={() => handleRemoveImage()}
                                                        disabled={disableClearImageButton}
                                                        sx={{ backgroundColor: "#8da9b6", color: "#d9d9d9", "&:hover": { backgroundColor: "#8da9b6" } }}>
                                                        Clear
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid container item direction="column" spacing={2.5} xs={6} sx={{ minWidth: "300px" }}>
                                            <Grid item><TextField {...detailsTextFieldParams} name="name" label="Project name" value={details.name} /></Grid>
                                            <Grid item>
                                                <TextField {...detailsTextFieldParams} name="description" label="Description" multiline rows={4} value={details.description} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </fieldset>
                            </Grid>
                            <Grid container item>
                                <fieldset style={{ fontSize: 20, border: "1px solid rgba(0, 0, 0, 0.2)", borderRadius: 5, width: "100%" }}>
                                    <legend>Blueprint</legend>
                                    <Grid container item direction="column" spacing={2.5} sx={{ padding: "3%" }}>
                                        <Grid container item>
                                            {blueprint ?
                                                <img src={blueprint} alt="" width="100%" height="100%" />
                                                :
                                                <Typography variant="body1" sx={{ color: "#495464" }}>No blueprint was added ;-(</Typography>
                                            }
                                        </Grid>
                                        <Grid container item spacing={2.5}>
                                            <Grid item>
                                                <label>
                                                    <input hidden type="file" accept="image/*" onChange={handleBlueprintChange} onClick={event => event.target.value = null} />
                                                    <Button color="secondary" variant="contained" component="span">Add</Button>
                                                </label>
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    onClick={() => handleRemoveBlueprint()}
                                                    disabled={disableClearBlueprintButton}
                                                    sx={{ backgroundColor: "#8da9b6", color: "#d9d9d9", "&:hover": { backgroundColor: "#8da9b6" } }}
                                                >
                                                    Clear
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </fieldset>
                            </Grid>
                            <ErrorBox showErrorMessage={showErrorMessage} errorMessage={errorMessage} />
                            <Grid container item justifyContent="flex-end">
                                <Grid item sx={{ mr: 2.5 }}>
                                    <Button variant="outlined" onClick={handleClose}>Cancel</Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        type="submit"
                                        disabled={disableSubmitButton}
                                        sx={{ backgroundColor: "#1b5e7d", color: "#d9d9d9", "&:hover": { backgroundColor: "#1b5e7d" } }}
                                    >
                                        Submit
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        {openCloseDialog && <CloseDialog open={openCloseDialog} setOpen={setOpenCloseDialog} handleClose={handleClose} />}
                    </form>
                </div>
            </Paper>
        </Modal>
    );
};

export default AddProjectModal;