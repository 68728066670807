import { Grid, Button, Chip, Autocomplete, TextField, Typography, Box, FormControlLabel, Checkbox, FormControl, InputLabel, Select, MenuItem, Link } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { DataGrid, GridToolbarContainer, useGridApiRef } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import AddProjectModal from "./AddProjectModal";
import TheSnackbar from "../Common/TheSnackbar";
import { UserContext } from "../../context/UserContext";
import { getDaysAgo } from "../../utils/utils";
import PrintIcon from "@mui/icons-material/Print";
import EditProjectModal from "./EditProjectModal";
import { axiosInstance } from "../../utils/utils";

const ProjectsTable = props => {
    const [userContext, setUserContext] = useContext(UserContext);
    const [rowCount, setRowCount] = useState(0);
    const [openAddProjectModal, setOpenAddProjectModal] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState();
    const [rowCountState, setRowCountState] = useState(rowCount);
    const [paginationModel, setPaginationModel] = useState({
        page: userContext.projectsPage ? userContext.projectsPage : 0,
        pageSize: window.localStorage.getItem("projectsPageSize") !== null ? parseInt(window.localStorage.getItem("projectsPageSize")) : 15
    });
    const [data, setData] = useState([]);
    const [sort, setSort] = useState(window.localStorage.getItem("projectsSort") !== null ? window.localStorage.getItem("projectsSort") : "");
    const [search, setSearch] = useState(userContext.projectsSearch ? userContext.projectsSearch : "");
    const chips = ["In Progress", "Completed"];
    const [selectedChips, setSelectedChips] = useState(userContext.selectedChips ? userContext.selectedChips : { "In Progress": true, "Completed": false });
    const [projectId, setProjectId] = useState();
    const [openEditProjectModal, setOpenEditProjectModal] = useState(false);

    const navigate = useNavigate();

    const handleClickEntry = (event, _id) => {
        if (event.target.tagName === "INPUT"
            || event.target.tagName === "SPAN"
            || event.target.tagName === "LABEL"
            || event.target.tagName === "A") {

            return;
        }

        navigate("/projects/" + _id);
    };

    const handleClickCompleted = (event, _id) => {
        const index = data.findIndex(project => project._id === _id);
        const updatedData = [...data];
        updatedData[index].completed = event.target.checked;
        setData(updatedData);

        let url = process.env.REACT_APP_API_URL + "/projects/" + _id;
        const payload = { _id: _id, completed: event.target.checked };
        const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

        axiosInstance.postForm(url, payload, config)
            .then(res => {
                setUserContext(userContext => ({ ...userContext, refresh: !userContext.refresh }));
            })
            .catch(err => {
                // Do nothing
            });
    };

    const handlePageChange = (newPaginationModel, scrollToTop = true) => {
        setPaginationModel(newPaginationModel);
        window.localStorage.setItem("projectsPageSize", newPaginationModel.pageSize);
        setUserContext(userContext => ({ ...userContext, projectsPage: newPaginationModel.page }));

        if (scrollToTop && props.topRef && props.topRef.current) {
            props.topRef.current.scrollIntoView();
        }
    };

    const getImage = image => {
        return process.env.REACT_APP_API_URL + "/images/" + image + "?token=" + userContext.token;
    };

    useEffect(() => {
        const fetchData = () => {
            if (paginationModel.pageSize === 0) {
                return;
            }

            let url = process.env.REACT_APP_API_URL + "/projects?page=" + paginationModel.page + "&limit=" + paginationModel.pageSize;
            const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

            if (sort) {
                url += "&sort=" + sort;
            }

            if (selectedChips["In Progress"] && !selectedChips["Completed"]) {
                url += "&completed=" + false;
            } else if (selectedChips["Completed"] && !selectedChips["In Progress"]) {
                url += "&completed=" + true;
            }

            if (search) {
                url += "&search=" + search;
            }

            axiosInstance.get(url, config)
                .then(res => {
                    setData(res.data.projects);
                    setRowCount(res.data.total);
                })
                .catch(err => {
                    // Do nothing
                });
        };

        fetchData();
    }, [userContext, setUserContext, sort, selectedChips, search, paginationModel]);

    useEffect(() => {
        setRowCountState(prevRowCountState => rowCount !== undefined ? rowCount : prevRowCountState);
    }, [rowCount, setRowCountState]);

    const handleOpenEdit = (event, _id) => {
        setProjectId(_id);
        setOpenEditProjectModal(true);
    };

    const rows = data;

    const columns = [{
        field: "_id",
        headerName: "Project",
        flex: 1,
        renderCell: params => {
            return (
                <Grid container direction="row" justifyContent="space-between" spacing={2.5} onClick={event => handleClickEntry(event, params.row._id)}>
                    <Grid container item direction="column" xs={6}>
                        <Grid item>
                            <Typography variant="h6" sx={{ cursor: "pointer", width: "fit-content" }}>
                                {params.row.name}
                            </Typography>
                            <Typography style={{ whiteSpace: "pre-line", cursor: "pointer", width: "fit-content" }}>
                                {params.row.description}
                            </Typography>
                            {userContext.admin &&
                                <Typography sx={{ cursor: "pointer", width: "fit-content" }}>
                                    <Link onClick={event => handleOpenEdit(event, params.row._id)} sx={{ color: "rgba(0, 0, 0, 0.65)", textDecoration: "underline", "&:hover": { cursor: "pointer" } }}>
                                        Edit
                                    </Link>
                                </Typography>
                            }
                            <Typography variant="body2" sx={{ color: "rgba(0, 0, 0, 0.5)", mt: "5px" }}>
                                Updated {getDaysAgo(params.row.updatedAt)} {userContext.token && params.row.updatedBy ? " by " + params.row.updatedBy.charAt(0).toUpperCase() + params.row.updatedBy.slice(1).toLowerCase() : null}
                            </Typography>
                            <Typography variant="body2" sx={{ color: "rgba(0, 0, 0, 0.5)" }}>
                                Created {userContext.token && params.row.createdBy ? "by " + params.row.createdBy.charAt(0).toUpperCase() + params.row.createdBy.slice(1).toLowerCase() + " on " : null} {new Date(params.row.createdAt).toDateString()}
                            </Typography>
                        </Grid>
                        {userContext.admin &&
                            <Grid item>
                                <FormControlLabel checked={params.row.completed} control={<Checkbox />} onChange={event => handleClickCompleted(event, params.row._id)} label="Completed" />
                            </Grid>
                        }
                    </Grid>
                    <Grid item xs={6}>
                        <Box display="flex" justifyContent="flex-end">
                            <div style={{ width: "100%", height: "200px", maxWidth: "300px" }}>
                                {params.row.image &&
                                    <div style={{ width: "100%", height: "100%" }}>
                                        <img src={getImage(process.env.REACT_APP_THUMBNAIL_PREFIX + params.row.image)} alt="" width="100%" height="100%" style={{ objectFit: "contain", objectPosition: "top left" }} />
                                    </div>
                                }
                            </div>
                        </Box>
                    </Grid>
                </Grid>
            );
        }
    }];

    const handleClickChip = chip => {
        const updatedSelectedChips = { ...selectedChips };

        for (const [key] of Object.entries(updatedSelectedChips)) {
            updatedSelectedChips[key] = key === chip;
        }

        setSelectedChips(updatedSelectedChips);
        handlePageChange({ page: 0, pageSize: paginationModel.pageSize }, false);
    };

    useEffect(() => {
        setUserContext(userContext => ({ ...userContext, selectedChips: selectedChips }));
    }, [selectedChips, setUserContext]);

    const handleSearch = event => {
        if (event.keyCode === 13) {
            setUserContext(userContext => ({ ...userContext, projectsSearch: event.target.value }));
            setSearch(event.target.value);
        }
    };

    const handleSort = event => {
        setSort(event.target.value);

        if (event.target.value) {
            window.localStorage.setItem("projectsSort", event.target.value);
        } else {
            window.localStorage.removeItem("projectsSort");
        }
    };

    const apiRef = useGridApiRef();

    const CustomToolbar = () =>
        <GridToolbarContainer
            sx={{
                "@media print": {
                    "& .css-13oo8r0-MuiGrid-root": { display: "none" },
                    "& .css-1ct0wh-MuiGrid-root": { display: "none" },
                    "& .css-blursa-MuiGrid-root": { display: "flex" },
                    "& .MuiTypography-root": { fontFamily: "Arial" }
                }
            }}
        >
            <Grid container spacing={2.5} sx={{ marginBottom: "25px", display: "none" }}>
                <Grid item>
                    <Typography variant="h5">List of Projects</Typography>
                </Grid>
            </Grid>
            <Grid container spacing={2.5} alignItems="center" sx={{ marginBottom: "25px" }}>
                <Grid item sx={{ flexGrow: 1 }}>
                    <Autocomplete
                        options={[]}
                        renderInput={params => (<TextField {...params} label="Search" onKeyDown={handleSearch} />)}
                        freeSolo
                        value={search}
                        sx={{ width: 300 }}
                    />
                </Grid>
                <Grid item>
                    <Button
                        size="medium"
                        sx={{ mt: 1.25, mb: 1.25 }}
                        onClick={() => apiRef.current.exportDataAsPrint({ hideFooter: true })}
                    >
                        <span style={{ marginLeft: "-4px", marginRight: "8px", display: "inherit" }}><PrintIcon sx={{ fontSize: 20 }} /></span>
                        Print
                    </Button>
                    {userContext.admin &&
                        <Button
                            size="medium"
                            sx={{ ml: 1.75, mt: 1.25, mb: 1.25, backgroundColor: "#1b5e7d", color: "#d9d9d9", "&:hover": { backgroundColor: "#1b5e7d" } }}
                            onClick={() => setOpenAddProjectModal(true)}
                        >
                            <span style={{ marginLeft: "-4px", marginRight: "8px", display: "inherit" }}><AddIcon sx={{ fontSize: 20 }} /></span>
                            Project
                        </Button>
                    }
                </Grid>
            </Grid>
            <Grid container item spacing={2.5} alignItems="center" sx={{ marginBottom: "25px" }}>
                <Grid container item spacing={1.5} xs={8}>
                    {chips.map((chip, index) => {
                        return (
                            <Grid item key={index}>
                                <Chip label={chip} variant={selectedChips[chip] ? "filled" : "outlined"} onClick={() => handleClickChip(chip)} />
                            </Grid>
                        );
                    })}
                </Grid>
                <Grid container item xs={4} justifyContent="flex-end">
                    <FormControl fullWidth size="small" sx={{ maxWidth: "200px" }}>
                        <InputLabel>Sort by</InputLabel>
                        <Select
                            value={sort}
                            label="Sort by"
                            onChange={handleSort}
                        >
                            <MenuItem value={"updatedAt:desc"}>Last updated</MenuItem>
                            <MenuItem value={"name:asc"}>Name</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </GridToolbarContainer >

    return (
        <Grid container item>
            <TheSnackbar open={openSnackbar} setOpen={setOpenSnackbar} message={snackbarMessage} />
            <DataGrid
                rows={rows}
                columns={columns}
                getRowHeight={() => "auto"}
                autoHeight
                disableRowSelectionOnClick
                disableColumnMenu
                getRowId={row => row._id}
                slots={{
                    toolbar: CustomToolbar,
                    noRowsOverlay: () => {
                        return <Typography variant="body1" align="center" sx={{ color: "#495464" }}>No project was added ;-(</Typography>;
                    }
                }}
                pageSizeOptions={[15, 30, 50, 100]}
                paginationMode="server"
                paginationModel={paginationModel}
                onPaginationModelChange={handlePageChange}
                rowCount={rowCountState}
                apiRef={apiRef}
                sx={{
                    fontSize: 16,
                    border: 0,
                    "& .MuiDataGrid-cell": {
                        paddingTop: "12px",
                        paddingBottom: "12px",
                        border: 0,
                        "&:focus-within, &:focus": { outline: "none" }
                    },
                    "& .MuiDataGrid-footerContainer": { border: 0 },
                    "& .MuiDataGrid-columnHeaders": { display: "none" },
                    "& .MuiDataGrid-row:hover": { borderRadius: "0px" },
                    "& .MuiDataGrid-row": { borderBottom: "1px solid rgba(0, 0, 0, 0.1)", },
                    "@media print": {
                        fontFamily: "Arial",
                        color: "#000000",
                        display: "table", // Fit columns onto page and avoid break inside rows
                        "& .MuiDataGrid-virtualScroller": { display: "table", overflow: "visible" }
                    }
                }}
            />
            {openAddProjectModal &&
                <AddProjectModal
                    openModal={openAddProjectModal}
                    setOpenModal={setOpenAddProjectModal}
                    setSnackbarMessage={setSnackbarMessage}
                    setOpenSnackbar={setOpenSnackbar}
                />
            }
            {openEditProjectModal &&
                <EditProjectModal
                    openModal={openEditProjectModal}
                    setOpenModal={setOpenEditProjectModal}
                    _id={projectId}
                    setSnackbarMessage={setSnackbarMessage}
                    setOpenSnackbar={setOpenSnackbar}
                />
            }
        </Grid>
    );
};

export default ProjectsTable;