import ProjectTable from "../components/Projects/ProjectTable";
import { Grid, Typography, IconButton, Box, Link } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { useState, useContext, useRef } from "react";
import logo from "../images/Luu Marine.png";
import { UserContext } from "../context/UserContext";

const Project = () => {
    const navigate = useNavigate();
    const [name, setName] = useState();
    const [description, setDescription] = useState();
    const [userContext] = useContext(UserContext);
    const topRef = useRef(null);

    return (
        <Grid container item direction="column" sx={{ maxWidth: "1000px" }} ref={topRef}>
            <Grid container item alignItems="center" wrap="nowrap" sx={{ padding: "25px 0px 25px 0px", mb: "25px", borderBottom: "1px solid black" }}>
                <Grid container item wrap="nowrap" alignItems="center" xs={8} sx={{ overflow: "hidden", mask: "linear-gradient(to right, rgba(0, 0, 0, 1) 0, rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, 0) 95%, rgba(0, 0, 0, 0) 0) 100% 50% / 100% 100% repeat-x" }}>
                    {userContext.token &&
                        <Grid item sx={{ mr: 2.5 }}>
                            <IconButton onClick={() => navigate("/main")} sx={{ "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.1)" } }}>
                                <ArrowBackIcon />
                            </IconButton>
                        </Grid>
                    }
                    <Grid item sx={{ mr: 2.5 }}>
                        <Typography variant="h5">{name}</Typography>
                        <Typography variant="h6">{description}</Typography>
                    </Grid>
                </Grid>
                <Grid item sx={{ flexGrow: 1 }} compoent={Link} to="/main">
                    <Box display="flex" justifyContent="flex-end">
                        <img src={logo} onClick={() => navigate("/main")} alt="" style={{ height: "120px" }} />
                    </Box>
                </Grid>
            </Grid>
            <ProjectTable setName={setName} setDescription={setDescription} topRef={topRef} />
        </Grid>
    );
};

export default Project;