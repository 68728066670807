import { useState, useEffect, useContext } from "react";
import { Button, Typography, Paper, Grid, TextField, Box } from "@mui/material";
import ErrorBox from "../components/Common/ErrorBox";
import PasswordAdornment from "../components/Common/PasswordAdornment";
import { UserContext } from "../context/UserContext";
import axios from "axios";
import logo from "../images/Luu Marine.png";

const Login = () => {
    const [inputs, setInputs] = useState({ username: "", password: "" });
    const [disableButton, setDisableButton] = useState(true);
    const [showPassword, setShowPassword] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [, setUserContext] = useContext(UserContext);
    const textFieldParams = { onChange: event => handleChange(event), sx: { width: 265 }, variant: "outlined" };

    const handleChange = event => setInputs(inputs => ({ ...inputs, [event.target.name]: event.target.value }));

    const handleSubmit = event => {
        event.preventDefault();
        setDisableButton(true);

        const url = process.env.REACT_APP_API_URL + "/users/login";
        const body = inputs;
        const config = { withCredentials: true };

        axios.post(url, body, config)
            .then(res => {
                setUserContext(userContext => ({ ...userContext, ...res.data }));
                window.location.href = "/main";
            })
            .catch(err => {
                setErrorMessage(err.response.data);
                setShowErrorMessage(true);
                setDisableButton(false);
            });
    };

    useEffect(() => setDisableButton(inputs.username === "" || inputs.password === ""), [inputs]);

    return (
        <Box align="center" style={{ marginLeft: 25, marginRight: 25 }}>
            <Paper sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                backgroundColor: "#8da9b6",
                padding: "30px 0px 80px 0px",
                marginTop: "15vh",
                marginBottom: "15vh",
                maxWidth: 535,
                height: 500
            }}>
                <Box style={{ marginBottom: 20 }}>
                    <img src={logo} alt="" style={{ height: "150px" }} />
                </Box>
                <form onSubmit={handleSubmit}>
                    <Grid container direction="column" spacing={2.5}>
                        <Grid item>
                            <TextField
                                type="text"
                                name="username"
                                autoFocus
                                label="Username"
                                {...textFieldParams}
                                inputProps={{ autoCapitalize: "none" }}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                type={!showPassword ? "password" : "text"}
                                name="password"
                                label="Password"
                                {...textFieldParams}
                                InputProps={{ endAdornment: <PasswordAdornment showPassword={showPassword} setShowPassword={setShowPassword} /> }}
                            />
                        </Grid>
                        <ErrorBox showErrorMessage={showErrorMessage} errorMessage={errorMessage} />
                        <Grid item align="center"><Button disabled={disableButton} type="submit">Log In</Button></Grid>
                    </Grid>
                </form>
            </Paper><br />
            <Typography variant="body2">&copy; 2024 TTP</Typography><br />
        </Box >
    );
};

export default Login;