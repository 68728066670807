import { Grid, Paper, Button, TextField, Typography, Modal, IconButton } from "@mui/material";
import ImageIcon from "@mui/icons-material/Image";
import { useState, useEffect, useContext, useCallback } from "react";
import ErrorBox from "../Common/ErrorBox";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DeleteDialog from "../Common/DeleteDialog";
import { UserContext } from "../../context/UserContext";
import { useNavigate, useLocation } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import CloseDialog from "../Common/CloseDialog";
import { axiosInstance } from "../../utils/utils";

const EditProjectModal = props => {
    const [disableSubmitButton, setDisableSubmitButton] = useState(false);
    const [disableClearImageButton, setDisableClearImageButton] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const detailsTextFieldParams = { type: "text", fullWidth: true, variant: "outlined", onChange: event => handleChange(event.target.name, event.target.value) };
    const [image, setImage] = useState();
    const [details, setDetails] = useState({ name: "", description: "" });
    const [blueprint, setBlueprint] = useState();
    const [disableClearBlueprintButton, setDisableClearBlueprintButton] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [userContext, setUserContext] = useContext(UserContext);
    const [imageModified, setImageModified] = useState(false);
    const [blueprintModified, setBlueprintModified] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const [modified, setModified] = useState(false);
    const [openCloseDialog, setOpenCloseDialog] = useState(false);
    const location = useLocation();

    const handleChange = (name, value) => {
        setDetails(pairs => ({ ...pairs, [name]: value }));
        setModified(true);
    };

    const handleImageChange = (event, value) => {
        handleChange("image", event.target.files[0]);
        setImage(URL.createObjectURL(event.target.files[0]));
        setImageModified(true);
        setModified(true);
    };

    const handleRemoveImage = () => {
        handleChange("image", "");
        setImage();
        setImageModified(true);
        setModified(true);
    };

    const handleClose = () => props.setOpenModal(false);

    useEffect(() => {
        const fieldsNotEmpty = () => {
            return details.name;
        };

        setDisableSubmitButton(!fieldsNotEmpty());
        setDisableClearImageButton(!details.image);
        setDisableClearBlueprintButton(!details.blueprint);
    }, [details]);

    const handleBlueprintChange = (event, value) => {
        handleChange("blueprint", event.target.files[0]);
        setBlueprint(URL.createObjectURL(event.target.files[0]));
        setBlueprintModified(true);
        setModified(true);
    };

    const handleRemoveBlueprint = () => {
        handleChange("blueprint", "");
        setBlueprint();
        setBlueprintModified(true);
        setModified(true);
    };

    const handleDelete = () => {
        const url = process.env.REACT_APP_API_URL + "/projects/" + props._id;
        const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

        axiosInstance.delete(url, config)
            .then(res => {
                props.setOpenModal(false);

                if (location.pathname === "/main") {
                    props.setSnackbarMessage("Project deleted");
                    props.setOpenSnackbar(true);
                    setUserContext(userContext => ({ ...userContext, refresh: !userContext.refresh }));
                } else {
                    navigate("/main", { replace: true, state: { snackbarMessage: "Project deleted" } });
                }
            })
            .catch(err => {
                setOpenDelete(false);
                setErrorMessage(err.response.data);
                setShowErrorMessage(true);
            });
    };

    const getImage = useCallback(image => {
        return process.env.REACT_APP_API_URL + "/images/" + image + "?token=" + userContext.token;
    }, [userContext.token]);

    useEffect(() => {
        const fetchEntry = () => {
            let url = process.env.REACT_APP_API_URL + "/projects/" + props._id;
            const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

            axiosInstance.get(url, config)
                .then(res => {
                    setDetails(details => ({ ...details, ...res.data }));

                    if (res.data.image) {
                        setImage(getImage(res.data.image));
                    }

                    if (res.data.blueprint) {
                        setBlueprint(getImage(res.data.blueprint));
                    }
                })
                .catch(err => {
                    // Do nothing
                });
        };

        fetchEntry();
    }, [props._id, userContext, setUserContext, getImage]);

    const handleSubmit = event => {
        event.preventDefault();
        setDisableSubmitButton(true);

        if (!imageModified) {
            delete details.image;
        }

        if (!blueprintModified) {
            delete details.blueprint;
        }

        const url = process.env.REACT_APP_API_URL + "/projects/" + props._id;
        const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };
        const payload = details;

        axiosInstance.postForm(url, payload, config)
            .then(res => {
                props.setOpenModal(false);
                props.setSnackbarMessage("Project modified");
                props.setOpenSnackbar(true);
                setUserContext(userContext => ({ ...userContext, refresh: !userContext.refresh }));
            })
            .catch(err => {
                setErrorMessage(err.response.data);
                setShowErrorMessage(true);
                setDisableSubmitButton(false);
            });
    };

    useEffect(() => setIsLoading(!details._id), [details]);

    const handleCloseWithChange = () => {
        if (modified) {
            setOpenCloseDialog(true);
        } else {
            props.setOpenModal(false);
        }
    };

    return (
        <Modal open={props.openModal} onClose={handleCloseWithChange}>
            <Paper
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    maxWidth: 825,
                    maxHeight: 700,
                    width: "90%",
                    height: "90%",
                    bgcolor: "#d9d9d9",
                    overflow: "auto"
                }}
            >
                <Grid container item justifyContent="flex-end" sx={{ padding: "2.5% 2.5% 2.5% 0" }}>
                    <IconButton onClick={handleCloseWithChange}><CloseIcon /></IconButton>
                </Grid>
                <div style={{ padding: "0% 6% 6% 6%" }}>
                    <Typography variant="h5" sx={{ mb: 0.5 }}>Edit Project</Typography>
                    <Typography variant="body1" sx={{ mb: 2.5, color: "rgba(49, 54, 64, 0.6)" }}>Modify the selected project</Typography>
                    {!isLoading &&
                        <form onSubmit={handleSubmit}>
                            <Grid container direction="column" spacing={2.5}>
                                <Grid container item>
                                    <fieldset style={{ fontSize: 20, border: "1px solid rgba(0, 0, 0, 0.2)", borderRadius: 5, width: "100%" }}>
                                        <legend>Details</legend>
                                        <Grid container item spacing={4.5} justifyContent="center" sx={{ padding: "3%" }}>
                                            <Grid container item direction="column" spacing={2.5} xs={6} sx={{ minWidth: "300px" }}>
                                                <Grid container item justifyContent="center">
                                                    <div style={{ width: "100%", height: "100%", maxWidth: "300px", aspectRatio: 1, borderRadius: "5px" }}>
                                                        {image ?
                                                            <img src={image} alt="" style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: "5px" }} />
                                                            :
                                                            <ImageIcon viewBox="3 3 18 18" sx={{ width: "100%", height: "100%" }} />
                                                        }
                                                    </div>
                                                </Grid>
                                                <Grid container item justifyContent="center" spacing={2.5}>
                                                    <Grid item>
                                                        <label>
                                                            <input hidden type="file" accept="image/*" onChange={handleImageChange} onClick={event => event.target.value = null} />
                                                            <Button color="secondary" variant="contained" component="span">Upload</Button>
                                                        </label>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button
                                                            onClick={() => handleRemoveImage()}
                                                            disabled={disableClearImageButton}
                                                            sx={{ backgroundColor: "#8da9b6", color: "#d9d9d9", "&:hover": { backgroundColor: "#8da9b6" } }}
                                                        >
                                                            Clear
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container item direction="column" spacing={2.5} xs={6} sx={{ minWidth: "300px" }}>
                                                <Grid item><TextField {...detailsTextFieldParams} name="name" label="Name" value={details.name} /></Grid>
                                                <Grid item>
                                                    <TextField {...detailsTextFieldParams} name="description" label="Description" multiline rows={4} value={details.description} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </fieldset>
                                </Grid>
                                <Grid container item>
                                    <fieldset style={{ fontSize: 20, border: "1px solid rgba(0, 0, 0, 0.2)", borderRadius: 5, width: "100%" }}>
                                        <legend>Blueprint</legend>
                                        <Grid container item direction="column" spacing={2.5} sx={{ padding: "3%" }}>
                                            <Grid container item>
                                                {blueprint ?
                                                    <img src={blueprint} alt="" width="100%" height="100%" />
                                                    :
                                                    <Typography variant="body1" sx={{ color: "#495464" }}>No blueprint was added ;-(</Typography>
                                                }
                                            </Grid>
                                            <Grid container item spacing={2.5}>
                                                <Grid item>
                                                    <label>
                                                        <input hidden type="file" accept="image/*" onChange={handleBlueprintChange} onClick={event => event.target.value = null} />
                                                        <Button color="secondary" variant="contained" component="span">Add</Button>
                                                    </label>
                                                </Grid>
                                                <Grid item>
                                                    <Button
                                                        onClick={() => handleRemoveBlueprint()}
                                                        disabled={disableClearBlueprintButton}
                                                        sx={{ backgroundColor: "#8da9b6", color: "#d9d9d9", "&:hover": { backgroundColor: "#8da9b6" } }}>
                                                        Clear
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </fieldset>
                                </Grid>
                                <ErrorBox showErrorMessage={showErrorMessage} errorMessage={errorMessage} />
                                <Grid container item>
                                    <Grid container item xs={2} alignItems="center">
                                        <IconButton onClick={() => setOpenDelete(true)}><DeleteOutlineIcon sx={{ fontSize: 30, color: "#495464" }} /></IconButton>
                                    </Grid>
                                    <Grid container item xs={10} justifyContent="flex-end" alignItems="center">
                                        <Grid item sx={{ mr: 2.5 }}>
                                            <Button variant="outlined" onClick={handleClose}>Cancel</Button>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                type="submit"
                                                disabled={disableSubmitButton}
                                                sx={{ backgroundColor: "#1b5e7d", color: "#d9d9d9", "&:hover": { backgroundColor: "#1b5e7d" } }}
                                            >
                                                Submit
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {openCloseDialog && <CloseDialog open={openCloseDialog} setOpen={setOpenCloseDialog} handleClose={handleClose} />}
                            {openDelete && <DeleteDialog open={openDelete} setOpen={setOpenDelete} handleDelete={handleDelete} type="project" />}
                        </form>
                    }
                </div>
            </Paper>
        </Modal>
    );
};

export default EditProjectModal;